<template>
  <div class="page-container">
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <!-- name -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Tên kiểu người dùng<span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
                :custom-messages="customMessages"
              >
                <b-form-input
                  id="basicInput"
                  v-model="userTypeNew.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
          >
            <!-- sign-name -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Mô tả</label>
              <b-form-input
                id="basicInput"
                v-model="userTypeNew.description"
              />
            </b-form-group>
          </b-col>

          <!-- quyền mặc định -->
          <b-col
            v-if="roleIdentity === 'DepartmentOfEducation'"
            md="6"
            xl="6"
          >
            <!-- sign-name -->
            <b-form-group label-for="basicInput">
              <b-form-checkbox
                v-model="userTypeNew.isDefault"
              >
                <label>Quyền mặc định</label>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div class="d-flex flex-wrap">
      <b-col
        v-for="(item, index) in dataPermission"
        :key="index"
        class="permission-content"
      >
        <liqui-tree
          :ref="`tree${index}`"
          class="tree-mini"
          :data="item"
          :options="options"
        >
        </liqui-tree>
      </b-col>
    </div>

    <!-- Action Buttons -->
    <div class="buton-group mt-1">
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="handleCreateUserType"
      >
        Lưu lại
      </b-button>
      <b-button
        variant="outline-primary"
        type="reset"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :to="{ name: 'list-user-type' }"
      >
        Quay lại
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

import LiquiTree from 'liquor-tree'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    LiquiTree,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      // dataPermission: [{ id: 1 }, { id: 2 }],
      options: {
        checkbox: true,
        propertyNames: {
          text: 'name',
          children: 'features',
          value: 'value',
        },
      },
      userTypeNew: {
        description: '',
        name: '',
        features: [],
        isDefault: false,
      },
      userTypeId: null,
      customMessages: {
        required: 'Tên kiểu người dùng là bắt buộc',
      },
      dataUserTypeDetail: {},
      dataPermission: [],
      roleIdentity: JSON.parse(localStorage.getItem('userData')).roleIdentity,
    }
  },
  watch: {
    dataUserTypeDetail: {
      immediate: true,
      handler(val) {
        if (val && this.$route.params.id) {
          this.userTypeNew = {
            description: this.dataUserTypeDetail.description,
            name: this.dataUserTypeDetail.name,
            isDefault: this.dataUserTypeDetail.isDefault,
          }
        }
      },
    },
  },
  async mounted() {
    let result = []
    if (this.$route.params.id) {
      result = await this.doFetchUserTypePermission(this.$route.params.id)
    } else {
      result = await this.doFetchUserTypePermission()
    }

    this.dataPermission = result?.data?.length > 0 ? result?.data : []
    this.dataPermission.forEach(ele => {
      switch (ele.name) {
        case 'DepartmentOfEducation':
          ele.name = 'Sở'
          break
        case 'Bussiness':
          ele.name = 'Doanh nghiệp'
          break
        case 'EducationDepartment':
          ele.name = 'Cấp phòng'
          break
        default:
          break
      }
    })
  },
  created() {
    if (this.$route.params.id) {
      this.userTypeId = this.$route.params.id
      this.fetchDetaiUserType(this.userTypeId)
    }
  },
  methods: {
    ...mapActions('userType', ['doFetchUserTypePermission']),

    // Lấy dữ liệu chi tiết kiểu người dùng
    async fetchDetaiUserType(id) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_USERTYPE}${id}`)
      this.dataUserTypeDetail = data
    },

    // create new User Type
    handleCreateUserType() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          await this.createUserType()
        }
      })
    },
    // Thêm kiểu người dùng
    async createUserType() {
      // lấy danh sách gán
      const pers = []
      // danh sách gán Sở
      // const selectTree2 = this.$refs.tree1
      const selectTree = []
      for (let i = 0; i < this.dataPermission.length; i++) {
        // eslint-disable-next-line no-underscore-dangle
        const selectTreeItem = this.$refs[`tree${i}`][0].find({}, true)
        if (selectTreeItem) {
          selectTree.push(selectTreeItem)
        }
      }

      selectTree.forEach(ele => {
        const selectFeature = []
        const selectNonFeature = []
        ele.forEach(item => {
          if (item.parent != null && item.children.length > 0 && (item.states.checked === true || item.states.indeterminate === true)) {
            selectFeature.push(item)
          } else if (item.parent != null && item.children.length > 0 && (item.states.checked === false && item.states.indeterminate !== true)) {
            selectNonFeature.push(item)
          }
        })
        selectFeature.forEach(element => {
          const feature = {
            parentId: element.parent.id,
            id: element.id,
            name: element.data.text,
            value: 0,
          }
          element.children.forEach(child => {
            if (child.children.length === 0 && child.states.checked === true) { feature.value += child.data.value }
          })
          pers.push(feature)
        })
        selectNonFeature.forEach(element => {
          const nonFeature = {
            parentId: element.parent.id,
            id: element.id,
            name: element.data.text,
            value: 0,
          }
          pers.push(nonFeature)
        })
      })

      // cập nhật kiểu người dùng
      const response = null
      this.userTypeNew.features = pers
      if (this.$route.params.id) {
        const payload = {
          ...this.userTypeNew,
          id: this.$route.params.id,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.UPDATE_USERTYPE, payload).then(res => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Cập nhật thành công', {
            title: 'Thông báo', variant: 'success', toaster: this.$toastPosition, solid: true,
          })
          this.$router.push({ name: 'list-user-type' })
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        })
      } else {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_USERTYPE, this.userTypeNew).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo thành công', {
              title: 'Thông báo', variant: 'success', toaster: this.$toastPosition, solid: true,
            })
            this.$router.push({ name: 'list-user-type' })
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(res.data?.errors[0], {
              title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
            })
          }
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/tree/tree.scss";
.permission-content {
  border: 1px solid #a6a8b0;
  box-sizing: border-box;
  border-radius: 6px;
  height: 100%;
  .tree-checkbox {
    border: 1px solid #a6a8b0;
  }
}
</style>
